import { TkgCardOneComponent } from "./tkg-card-one/tkg-card-one.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import { TextMaskModule } from "angular2-text-mask";
import { MatIconModule } from "@angular/material/icon";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { MatSelectModule } from "@angular/material/select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { DocumentPipe } from "./document.pipe";
import { PostcodePipe } from "./postcode.pipe";
import { EventsService } from "./events.service";
import { CoreModule } from "../core/core.module";
import { MessageService } from "./message.service";
import { FormatDatePipe } from "./format-date.pipe";
import { WebsocketService } from "./websocket.service";
import { StateBooleanPipe } from "./state-boolean.pipe";
import { FilterValuePipe } from "./pipe/filter-value.pipe";
import { SearchComponent } from "./search/search.component";
import { RepeatTypeComponent } from "./repeat-section.type";
import { LoaderComponent } from "./loader/loader.component";
import { ListEmptyComponent } from "./list-empty/list-empty.component";
import { DetailsMapsComponent } from "./details-maps/details-maps.component";
import { MapsGenericComponent } from "./maps-generic/maps-generic.component";
import { DialogAlertComponent } from "./dialog-alert/dialog-alert.component";
import { DynamicDialogComponent } from "./dynamic-dialog/dynamic-dialog.component";
import { DialogConfirmComponent } from "./dialog-confirm/dialog-confirm.component";
import { DocumentsDialogComponent } from "./documents-dialog/documents-dialog.component";
import { DialogExceptionsComponent } from "./dialog-exceptions/dialog-exceptions.component";
import { DialogLinkDriverComponent } from "./dialog-link-driver/dialog-link-driver.component";
import { DialogChangeOperation } from "./dialog-change-operation/dialog-change-operation.component";
import { DialogUnlinkDriverComponent } from "./dialog-unlink-driver/dialog-unlink-driver.component";
import { DialogConfirmValetComponent } from "./dialog-confirm-valet/dialog-confirm-valet.component";
import { FormlyInputCustomComponent } from "./formly/formly-input-custom/formly-input-custom.component";
import { FormlyInputSearchComponent } from "./formly/formly-input-search/formly-input-search.component";
import { FormlyInputSearchCnpjComponent } from "./formly/formly-input-search-cnpj/formly-input-search-cnpj.component";
import { FormlyAutocompleteComponent } from "./formly/formly-autocomplete/formly-autocomplete.component";
import { FormlyScheduleCodeComponent } from "./formly/formly-schedule-code/formly-schedule-code.component";
import { FormlyDatepickerRangeComponent } from "./formly/formly-datepicker-range/formly-datepicker-range.component";
import { FormlyInputTagsOutlineComponent } from "./formly/formly-input-tags-outline/formly-input-tags-outline.component";
import { TkgCardDetailsComponent } from "./tkg-card-details/tkg-card-details.component";
import { StepsFilterDialogComponent } from "./steps-filter-dialog/steps-filter-dialog.component";
import { CnpjPipe } from "./format-cnpj.pipe";
import { SimpleTableComponent } from "./simple-table/simple-table.component";
import { PaginateSortTableComponent } from "./paginate-sort-table/paginate-sort-table.component";
import { ShowLocationsMapComponent } from "./show-locations-map/show-locations-map.component";
import { LoadingTableComponent } from "./loading-table/loading-table.component";
import { AssignQualcommDeviceComponent } from "./assign-qualcomm-device/assign-qualcomm-device.component";
import { FormlySsDriverComponent } from "src/app/shared/formly/formly-ss-driver/formly-ss-driver.component";
import { FormlySsCarrierComponent } from "./formly/formly-ss-carrier/formly-ss-carrier.component";
import { FormlySsQualcommDeviceComponent } from "./formly/formly-ss-qualcomm-device/formly-ss-qualcomm-device.component";
import { FormlySsOperationComponent } from "./formly/formly-ss-operation/formly-ss-operation.component";
import { FormlySsSuboperationComponent } from "./formly/formly-ss-suboperation/formly-ss-suboperation.component";
import { FormlySsScheduleComponent } from "./formly/formly-ss-schedule/formly-ss-schedule.component";
import { FormlySsVehicleTypeComponent } from "./formly/formly-ss-vehicle_type/formly-ss-vehicle_type.component";
import { SkeletonDirective } from "./skeleton/skeleton.directive";
import { RectComponent } from "./skeleton/rect/rect.component";
import { FormlySsFlowComponent } from "./formly/formly-ss-flow/formly-ss-flow.component";
import { WeightDialogComponent } from "./weight-dialog/weight-dialog.component";
import { FlowDialogComponent } from "./flow/flow-dialog.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DialogResourceNameComponent } from "./dialog-resource-name/dialog-resource-name.component";
import { ExceptionsControlDialogComponent } from "./exceptions-control-dialog/exceptions-control-dialog.component";
import { CallToActionConfirmComponent } from "./call-to-action-confirm/call-to-action-confirm.component";
import { UploadListComponent } from "./upload-list/upload-list.component";
import { GridListComponent } from "./grid-list/grid-list.component";
import { FormlyDialogCheckedListComponent } from "./formly/formly-dialog-checked-list/formly-dialog-checked-list.component";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { BpAddDialogComponent } from "./bp-add-dialog/bp-add-dialog.component";
import { SetPreScheduleDateTimeComponent } from "./set-pre-schedule-date-time/set-pre-schedule-date-time.component";
import { CommentsComponent } from "./comments/comments.component";
import { FormlyInputSearchDriverComponent } from "./formly/formly-input-search-driver/formly-input-search-driver.component";
import { ObjectTypeComponent } from "./object.type";
import { FormlySsTimeslotComponent } from "./formly/formly-ss-timeslot/formly-ss-timeslot.component";
import { WebBtnFiltersComponent } from "./web-btn-filters/web-btn-filters.component";
import { FormlySsSupportDataComponent } from "./formly/formly-ss-support-data/formly-ss-support-data.component";
import { FormlySelectSupportDataComponent } from "./formly/formly-select-support-data/formly-select-support-data.component";
import { FormulySsStatusComponent } from "./formly/formuly-ss-status/formuly-ss-status.component";
import { WeekDaysListComponent } from "./week-days-list/week-days-list.component";
import { DialogDeleteRecurringScheduleComponent } from "./dialog-delete-recurring-schedule/dialog-delete-recurring-schedule.component";
import { ChecklistDynamicFormComponent } from "./checklist-dynamic-form/checklist-dynamic-form.component";
import { SignaturePadComponent } from "./signature-pad/signature-pad.component";
import { FormlySignaturePadComponent } from "./formly/formly-signature-pad/formly-signature-pad.component";
import { AddDialogTasksComponent } from "./dialog-tasks/add-dialog-tasks/add-dialog-tasks.component";
import { AssignDialogTasksComponent } from "./dialog-tasks/assign-dialog-tasks/assign-dialog-tasks.component";

@NgModule({
  imports: [
    DragDropModule,
    CommonModule,
    CoreModule,
    LeafletModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      validationMessages: [
        { name: "required", message: "Este campo é obrigatório" },
      ],
      types: [
        { name: "repeat", component: RepeatTypeComponent },
        { name: "SelectSearchDriver", component: FormlySsDriverComponent },
        { name: "SelectSearchCarrier", component: FormlySsCarrierComponent },
        {
          name: "SelectSearchQualcommDevice",
          component: FormlySsQualcommDeviceComponent,
        },
        {
          name: "SelectSearchOperation",
          component: FormlySsOperationComponent,
        },
        {
          name: "SelectSearchSuboperation",
          component: FormlySsSuboperationComponent,
        },
        {
          name: "SelectSearchVehicleType",
          component: FormlySsVehicleTypeComponent,
        },
        {
          name: "SelectSearchAprovedSchedules",
          component: FormlySsScheduleComponent,
        },
        { name: "SelectSearchFlow", component: FormlySsFlowComponent },
        {
          name: "DialogCheckedList",
          component: FormlyDialogCheckedListComponent,
        },
        { name: "object", component: ObjectTypeComponent },
        {
          name: "SelectSearchSupportData",
          component: FormlySsSupportDataComponent,
        },
        {
          name: "SelectSearchStatus",
          component: FormulySsStatusComponent,
        },
        {
          name: "SelectSupportData",
          component: FormlySelectSupportDataComponent,
        },
        {
          name: "SelectWeekDays",
          component: WeekDaysListComponent,
        },
        {
          name: "SignaturePad",
          component: FormlySignaturePadComponent,
        },
      ],
    }),
    TextMaskModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SimpleTableComponent,
    PaginateSortTableComponent,
    LoadingTableComponent,
    ShowLocationsMapComponent,
    UploadListComponent,
    NgxDocViewerModule,
    // WebBtnFiltersComponent,
    SignaturePadComponent,
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    // NgxMatNativeDateModule,
  ],
  declarations: [
    CnpjPipe,
    DialogAlertComponent,
    DialogConfirmComponent,
    DialogLinkDriverComponent,
    DialogUnlinkDriverComponent,
    DialogChangeOperation,
    DialogExceptionsComponent,
    DocumentsDialogComponent,
    StateBooleanPipe,
    SearchComponent,
    DocumentPipe,
    PostcodePipe,
    ListEmptyComponent,
    FormatDatePipe,
    MapsGenericComponent,
    DetailsMapsComponent,
    LoaderComponent,
    FormlyInputTagsOutlineComponent,
    FilterValuePipe,
    DynamicDialogComponent,
    RepeatTypeComponent,
    DialogConfirmValetComponent,
    FormlyDatepickerRangeComponent,
    FormlyScheduleCodeComponent,
    FormlyInputCustomComponent,
    FormlyAutocompleteComponent,
    FormlyInputSearchComponent,
    FormlyInputSearchCnpjComponent,
    FormlyInputSearchDriverComponent,
    TkgCardOneComponent,
    TkgCardDetailsComponent,
    StepsFilterDialogComponent,
    AssignQualcommDeviceComponent,
    FormlySsDriverComponent,
    FormlySsCarrierComponent,
    FormlySsQualcommDeviceComponent,
    FormlySsOperationComponent,
    FormlySsSuboperationComponent,
    FormlySsVehicleTypeComponent,
    FormlySsScheduleComponent,
    AssignQualcommDeviceComponent,
    SkeletonDirective,
    RectComponent,
    FormlySsScheduleComponent,
    FormlySsFlowComponent,
    WeightDialogComponent,
    FlowDialogComponent,
    DialogResourceNameComponent,
    ExceptionsControlDialogComponent,
    CallToActionConfirmComponent,
    GridListComponent,
    FormlyDialogCheckedListComponent,
    BpAddDialogComponent,
    SetPreScheduleDateTimeComponent,
    CommentsComponent,
    ObjectTypeComponent,
    FormlySsTimeslotComponent,
    WebBtnFiltersComponent,
    FormlySsSupportDataComponent,
    FormlySelectSupportDataComponent,
    FormulySsStatusComponent,
    WeekDaysListComponent,
    DialogDeleteRecurringScheduleComponent,
    ChecklistDynamicFormComponent,
    //SignaturePadComponent,
    FormlySignaturePadComponent,
    AddDialogTasksComponent,
    AssignDialogTasksComponent,
    // FormlyInputDatetimeComponent,
  ],
  providers: [
    WebsocketService,
    MessageService,
    EventsService,
    provideNgxMask(),
  ],
  exports: [
    DialogAlertComponent,
    DialogConfirmComponent,
    DialogLinkDriverComponent,
    DialogUnlinkDriverComponent,
    DialogChangeOperation,
    DialogExceptionsComponent,
    DocumentsDialogComponent,
    ListEmptyComponent,
    StateBooleanPipe,
    SearchComponent,
    DocumentPipe,
    PostcodePipe,
    FormatDatePipe,
    LoaderComponent,
    FilterValuePipe,
    DynamicDialogComponent,
    FormsModule,
    ReactiveFormsModule,
    DialogConfirmValetComponent,
    TkgCardOneComponent,
    CnpjPipe,
    SkeletonDirective,
    WebBtnFiltersComponent,
    AddDialogTasksComponent,
    AssignDialogTasksComponent,
  ],
})
export class SharedModule {}
