import { DialogLinkDriverComponent } from "../../shared/dialog-link-driver/dialog-link-driver.component";
import { DialogUnlinkDriverComponent } from "../../shared/dialog-unlink-driver/dialog-unlink-driver.component";
import { ScheduleDinamicFormDialogComponent } from "../../scheduling/schedule-dinamic-form-dialog/schedule-dinamic-form-dialog.component";
import { DialogChangeOperation } from "../../shared/dialog-change-operation/dialog-change-operation.component";
import { InfoFormDialogComponentComponent } from "../info-form-dialog-component/info-form-dialog-component.component";
import { ShowInfoDialogComponent } from "../show-info-dialog/show-info-dialog.component";
import { ReleaseDockDialogComponent } from "../release-dock-dialog/release-dock-dialog.component";
import { DockManagementService } from "../../docks/shared/dock-management.service";
import { SoundService } from "src/app/shared/sound.service";

import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  HostListener,
  OnDestroy,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  lastValueFrom,
  merge,
  Observable,
  of as observableOf,
  of,
  Subject,
  Subscription,
} from "rxjs";
import {
  catchError,
  map,
  startWith,
  switchMap,
  tap,
  debounceTime,
  auditTime,
  exhaustMap,
  takeUntil,
  filter,
} from "rxjs/operators";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Checkin } from "../checkin.interface";

// Serviços
import { CarrierService } from "src/app/carrier/shared/carrier.service";
import { CheckinService } from "../../checkin/shared/checkin.service";
import { SupportDataService } from "../../shared/support-data.service";
import { environment } from "./../../../environments/environment.prod";

// Diálogos
import { CheckinDriverParkingLotComponent } from "../checkin-driver-parking-lot/checkin-driver-parking-lot.component";
import { DialogConfirmComponent } from "../../shared/dialog-confirm/dialog-confirm.component";
import { InvalidateVehicleDialogComponent } from "../invalidate-vehicle-dialog/invalidate-vehicle-dialog.component";
import { DetailsMapsComponent } from "../../shared/details-maps/details-maps.component";
import { AditionalInfoDialogComponent } from "../aditional-info-dialog/aditional-info-dialog.component";
import { FinishOptionsDialogComponent } from "../finish-options-dialog/finish-options-dialog.component";
import { EditKeysDialogComponent } from "../edit-keys-dialog/edit-keys-dialog.component";
import { DialogExceptionsComponent } from "../../shared/dialog-exceptions/dialog-exceptions.component";
import { DocumentsDialogComponent } from "../../shared/documents-dialog/documents-dialog.component";
import { OtherMotivesGenericDialogComponent } from "../other-motives-generic-dialog/other-motives-generic-dialog.component";
import { DynamicDialogComponent } from "src/app/shared/dynamic-dialog/dynamic-dialog.component";

// import * as _ from "lodash";
import * as moment from "moment";

import { GenericDialogComponent } from "../generic-dialog/generic-dialog.component";

//Reproduzir som
import { Howl } from "howler";
// import { DirectDialogComponent } from "src/app/checkin/direct-dialog/direct-dialog.component";
import { DockAssignDialogComponent as DirectDialogComponent } from "src/app/docks/dock-assign-dialog/dock-assign-dialog.component";
import { WSService2 } from "src/app/shared/websocket/wss2.service";
// import { WssService } from 'src/app/shared/websocket/wss.service';
import { DialogConfirmValetComponent } from "src/app/shared/dialog-confirm-valet/dialog-confirm-valet.component";
import { DialogAlertComponent } from "src/app/shared/dialog-alert/dialog-alert.component";
import { GenericDialogComponent as MicroserviceDialog } from "../../micro-services/generic-dialog/generic-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { BottomSheetActionsComponent } from "../bottom-sheet-actions/bottom-sheet-actions.component";
import { Inject } from "@angular/core";
import { AccountService } from "src/app/account/shared/account.service";
import { AssignQualcommDeviceComponent } from "src/app/shared/assign-qualcomm-device/assign-qualcomm-device.component";
import { OptionsDialogComponent } from "../options-dialog/options-dialog.component";
import { WeightDialogComponent } from "src/app/shared/weight-dialog/weight-dialog.component";
import { FlowDialogComponent } from "src/app/shared/flow/flow-dialog.component";
import { ExceptionsControlDialogComponent } from "src/app/shared/exceptions-control-dialog/exceptions-control-dialog.component";
import JourneyStatusTimelineRender from "src/app/timelines/journey-status-timeline/journey-status-timeline-render";
import { CallToActionConfirmComponent } from "src/app/shared/call-to-action-confirm/call-to-action-confirm.component";
import { AgendamentoService } from "src/app/multiagendamento/shared/agendamento.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { ChecklistDynamicFormComponent } from "src/app/shared/checklist-dynamic-form/checklist-dynamic-form.component";
import { AssignDialogTasksComponent } from "src/app/shared/dialog-tasks/assign-dialog-tasks/assign-dialog-tasks.component";
import { ChecklistService } from "src/app/shared/checklist.service";

@Component({
  selector: "app-checkin-list",
  templateUrl: "./checkin-list.component.html",
  styleUrls: ["./checkin-list.component.scss"],
})
export class CheckinListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatMenuTrigger) public trigger?: MatMenuTrigger;
  page_with_status: any[] = []; // Será utilizado para controlar requisições de insert e update
  block_actions = false; // Utilizado para bloquear os botões de ação até que a requisição seja completada.
  selectedCheckinID: number;
  account: any;
  filterWidth: string = "25%";
  path!: string;
  cols: any[] = [];
  rows: any[] = [];
  // columnsToDisplay!: string[];
  // displayColumns!: string[];
  search!: string;
  paginatorLength = 0;
  paginatorPageSize = 10;
  endpoint!: string;
  DisplayDefined = false;
  needsRefreshOnWsMessage = false;
  reloadingDataSource = false;
  pauseRefreshOnWsMessage = false;
  driverCheckinIdList: any[] = [];
  // Novo filtro acessado por botão
  btnFilterList: any[] = [];

  // Desativar atualização do WS quando: clicar num filtro, clicar no botão de ações
  // Atualizar apenas o registro

  addButton = false;
  fabMenu = [
    {
      icon: "add",
      action: "getDynamicForm",
      name: "Novo check in",
      title: "Adicionar entrada",
      urlAPI: "checkins-update-resource",
      dynamic_form_name: "add_new_checkin_dialog_fields",
      dialog_size: "dialog-medium",
      status: "waiting",
      item: { id: null },
    },
  ];

  wsSubscription!: Subscription;

  listOtProblems: any[] = [];
  listDestinyLocation: any[] = [];
  listLoadingDenied: any[] = [];
  listUnloadingDenied: any[] = [];
  listLoadingProblems: any[] = [];
  listReceivedWithException: any[] = [];
  listDirectionProblem: any[] = [];
  listInvalidatedDocumentation: any[] = [];

  length!: number;
  displayedColumns!: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<Checkin>();
  searching = false;
  protected _onDestroy = new Subject<void>();

  data: Checkin[] = [];
  rawData: any = [];
  operations: any = [];
  motorista_destino: any = [];
  suboperations: any = [];
  suboperationsList: any = [];
  vehicle_types: any = [];
  transportes: any = [];
  dockSpaces: any = [];
  dockSpacesFilterList: any = [];
  selectedDocks: any = [];
  listStatus: any = [];
  listActionRef: any = [];
  listAction: any = [];
  departments: any = [];
  filterLabels: any = {};
  allDataAvailable: boolean = false;
  dashBordUpdateKey!: string;
  isWeb = true;
  wsHost!: string;
  carrier_id: number | string = "";
  destinyAll: any = [];
  operatorAll: any = [];
  valetStatusAll: any = [];
  operation_id: any = "";
  suboperation: any = "";
  categories: any = [];
  department_id: any = "";
  status: any = "";
  disableUnlink: any;
  vehicleTypesLoaded = false;
  optionsFilter: any = {};
  subOperationSelected: any[] = [];
  allowSound = false;
  listStatusSound: any = [];

  scheduleFieldsOptions: any = {};

  showOptionsOperations = false;
  showOptionsSuboperations = false;

  filterStatus!: any;
  filterStatusSelected: UntypedFormControl = new UntypedFormControl();
  filterOperationSelected: UntypedFormControl = new UntypedFormControl();
  filterSubOperationSelected: UntypedFormControl = new UntypedFormControl();
  filterCarrierSelected: UntypedFormControl = new UntypedFormControl();
  filterDepartmentSelected: UntypedFormControl = new UntypedFormControl();
  filterDestinySelected: UntypedFormControl = new UntypedFormControl();
  searchFilter: UntypedFormControl = new UntypedFormControl();
  filterDockSpaceSelected: UntypedFormControl = new UntypedFormControl();
  //Filtros de dados do agendamento vinculado ao check-in
  eventFiltersForm: FormGroup;
  eventFilterList: any[] = [];
  eventFiltersSubscription: Subscription[] = [];

  actualPage: string;
  showCarrierFilter: boolean = false;
  showOperationFilter: boolean = false;
  showStatusFilter: boolean = false;
  ShowSearchFilter: boolean = false;
  columns: any;
  // private socket!: WebSocketSubject<any>;
  sound: any;
  soundSubscription: Subscription;
  selectedOperation: any;
  selectedDepartment: any;
  selectedStatus: any;
  expandedElement: any;

  // Recebe o id de todos os check ins requisitados - controle de atualização
  // O websocket vai trazer nas mensagem o tipo de operação: 1|2 (add|edit). Caso a operação
  // seja edit, vai trazer também o id do registro que foi alterado. Esse id é passado na
  // na API de check in que deve retorna o check in individualizado. Ao receber a resposta da
  // API, o objeto de check in deve ser iterado e alterado apenas o registro que teve atualização
  idList: any[];
  // Registra o maior id dos check ins requisitados - controle de inclusão
  idMax: number;
  actionsColumn: boolean;
  bottomSheet: boolean;
  searchFilterSubscription: Subject<any[]>;
  checkinDataSubscribe: Subscription;
  addButtonParams: any;
  dynamic_filter: boolean = false;

  constructor(
    private dockManagementService: DockManagementService,
    public checkinService: CheckinService,
    private carrierService: CarrierService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public breakpointObserver: BreakpointObserver,
    public supportDataService: SupportDataService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    public checklistServide: ChecklistService,
    // private websocketService: WssService,
    private wssService: WSService2,
    private _bottomSheet: MatBottomSheet,
    private fb: FormBuilder,
    private accountService: AccountService,
    private soundService: SoundService,
    private agendamentoService: AgendamentoService,
  ) {
    this.account = JSON.parse(localStorage.getItem("account")!);
    this.page_with_status =
      this.account.system_client.resource.environment.with_status;

    this.route.paramMap.subscribe((params) => {
      this.actualPage = params.get("page")!;
      this.checkAddBtn();
      this.getAllowSound();
      this.getSoundStatus();
    });

    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Web,
        Breakpoints.XSmall,
      ])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });
  }

  checkAddBtn() {
    this.addButton = false;
    if (
      this.account.system_client.resource.environment.pages[
        this.actualPage
      ].hasOwnProperty("add_button")
    ) {
      this.addButtonParams =
        this.account.system_client.resource.environment.pages[this.actualPage][
          "add_button"
        ];
      this.addButton = this.addButtonParams["active"] || false;
    }
  }

  activateLayout() {
    // this.columnsToDisplay = this.cols;
    // this.displayColumns = [];
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      console.log("smart phone");
      // this.columnSize = 1;
      this.actionsColumn = false;
      this.bottomSheet = true;
      // this.router.navigate([`admin/checkins-mobile/${this.actualPage}`]);
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletPortrait)) {
      console.log("tablet portrait");
      this.actionsColumn = false;
      this.bottomSheet = true;
      // this.columnSize = 2;
      // this.router.navigate([`admin/checkins-mobile/${this.actualPage}`]);
    } else if (this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)) {
      console.log("tablet landscape");
      this.actionsColumn = false;
      this.bottomSheet = true;
      // this.columnSize = 3;
      // this.router.navigate([`admin/checkins-mobile/${this.actualPage}`]);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
      this.actionsColumn = true;
      this.bottomSheet = false;
      this.router.navigate([`admin/checkins/${this.actualPage}`]);
    }
  }

  startRequisitions() {
    this.supportDataService
      .generic({ name: "ot_problem" })
      .subscribe((r: any) => {
        this.listOtProblems = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "locations" })
      .subscribe((r: any) => {
        this.destinyAll = this.listDestinyLocation = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "loading_denied" })
      .subscribe((r: any) => {
        this.listLoadingDenied = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "unloading_denied" })
      .subscribe((r: any) => {
        this.listUnloadingDenied = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "exception_received" })
      .subscribe((r: any) => {
        this.listReceivedWithException = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "loading_problem" })
      .subscribe((r: any) => {
        this.listLoadingProblems = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "direction_problem" })
      .subscribe((r: any) => {
        this.listDirectionProblem = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "invalidated_documentation" })
      .subscribe((r: any) => {
        this.listInvalidatedDocumentation = r.data.resource;
      });

    this.supportDataService
      .generic({ name: "scheduling_dialog_fields" })
      .subscribe((r: any) => {
        if (r.data && r.data.resource) {
          []
            .concat(r.data.resource)
            .filter((x) => x)
            .map((o: any) => {
              if (o.key && o.props) {
                this.scheduleFieldsOptions[o.key] = []
                  .concat(o.props?.options)
                  .filter((x) => x);
              }
            });
        }
      });
  }

  showCellValue(value: any) {
    if (typeof value !== "object") {
      return value;
    } else {
      return "";
    }
  }

  ngOnDestroy(): void {
    if (!this.wssService.socket$.closed.valueOf()) {
      this.wssService.closeConnection();
    }
    this.searchFilterSubscription.unsubscribe();
    this.eventFiltersSubscription.forEach((subscription) =>
      subscription.unsubscribe(),
    );
    // this.checkinDataSubscribe.unsubscribe();
  }

  ngOnInit() {
    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.visibilityState === "visible") {
          if (this.wssService.socket$.closed.valueOf()) {
            this.wssService.closeConnection();
          } else {
            this.subscribeWebsocket();
          }
        }
      },
      false,
    );

    this.dialog.afterOpened.subscribe(({ componentInstance }) => {
      this.selectedCheckinID = componentInstance.data?.id ?? null;
    });

    this.dynamic_filter =
      this.account.system_client.resource.environment.pages[
        this.actualPage
      ].dynamic_filter;

    this.activateLayout();
    let pageInit = true;
    this.route.paramMap.subscribe((params) => {
      this.endpoint =
        this.account.system_client.resource.environment.pages[this.actualPage][
          "api_endpoint"
        ] || "checkins";
      const pageOptions = this.getPageOptions();

      this.filterStatusSelected.setValue("", { emitEvent: false });
      this.filterOperationSelected.setValue("", { emitEvent: false });
      this.filterSubOperationSelected.setValue("", { emitEvent: false });
      this.filterSubOperationSelected.disable();
      this.filterCarrierSelected.setValue("", { emitEvent: false });
      this.filterDepartmentSelected.setValue("", { emitEvent: false });
      this.filterDestinySelected.setValue("", { emitEvent: false });
      this.filterDockSpaceSelected.setValue("", { emitEvent: false });
      //Inicializa os filtros de dados do agendamento (Tabela event_events)
      this.eventFiltersForm = this.fb.group({
        eventFilters: this.fb.array([]),
      });

      //Reset em alguns dos atributos do objeto optionsFilter, seguindo a lógica dos campos logo acima, os quais também foram limpos.
      Object.assign(this.optionsFilter, {
        carrier_id: "",
        motorista_destino: "",
        with_status: pageOptions.with_status?.join("|") || "",
        departments_id: "",
        operation_id: "",
        suboperation: "",
        dock_space: "",
        with_operations: pageOptions.with_operations?.join("|") || "",
      });

      this.status = "";
      this.selectedDocks = [];
      this.getColumns();
      this.getActions();
      this.labelFilterEnabled();
      //Ativa os filtros de dados do agendamento dependendo do que foi definido na system_clients
      this.createEventFilterControls();

      if (!pageInit && this.dynamic_filter == false) {
        this.loadCheckins(true);
      } else {
        this.updateFilterList(this.btnFilterList);
      }
    });

    Object.assign(this.optionsFilter, {
      orderBy: this.sort?.active || "created_at",
      sortedBy: this.sort?.direction || "ASC",
      pageSize: this.paginator?.pageSize || 10,
      page: (this.paginator?.pageIndex || 0) + 1,
    });

    if (
      this.account.system_client.resource.environment.docks_shared ===
        "false" ||
      this.account.department_id?.length <= 1
    ) {
      this.showOptionsOperations = true;
      this.showOptionsSuboperations = true;
    }

    // SupportData
    this.getSupportData();

    this.startRequisitions();
    this.getOperations();
    this.getSuboperations();
    this.getCarrier();

    this.checkSizeScreen();

    pageInit = false;
  }

  ngAfterViewInit(): void {
    this.paginatorObserver();
    this.subscribeWebsocket();
  }

  onActionMenuClosed() {
    if (this.needsRefreshOnWsMessage && !this.pauseRefreshOnWsMessage) {
      // console.log("via onActionMenuClosed");
      this.loadCheckins();
    }
  }

  subscribeWebsocket() {
    // console.log("subscribeWebSocket");
    let id = JSON.parse(localStorage.getItem("account")!).system_client.id;
    this.wssService.CHANNEL = `/checkin/${id}`;
    this.wssService.connect().subscribe((msg: any) => {
      const wsMsg: any = Object.assign({}, msg);
      if (wsMsg.hasOwnProperty("action")) {
        if (wsMsg.action === 2) {
          // console.log("action 2");
          // Update -> Define mensagem de update
          // Verifica quais status são visíveis na página
          const statusShowedInThisPage =
            this.account.system_client.resource.environment.pages[
              this.actualPage
            ]["with_status"];
          // console.log(statusShowedInThisPage);
          // Chama atualização se: 1) o status da atualização está incluso na lista de status da página;
          //  2) Se o driver_checkin_id está sendo mostrado na listagem (Implementar depois)
          // (porém pode ser um novo registro para ser adicionado a página)
          if (statusShowedInThisPage.includes(wsMsg.status)) {
            this.makeSound(wsMsg.status);
            this.evaluateDataUpdate(wsMsg);
          } else {
            // console.log("Status de outra página");
            // Status diz respeito a outra página, então verifica se o driver_checkin_id está sendo exibido na tela. Se sim, faz o update
            if (this.driverCheckinIdList.includes(wsMsg.driver_checkin_id)) {
              // ToDo: remover apenas o id do objeto
              this.evaluateDataUpdate({ msg: null });
            }
          }
        } else {
          // Após implementação na API do app, remover este trecho (else) e descomentar o trecho acima
          if (wsMsg.msg) {
            this.snackBarMessage(wsMsg);
          } else {
            this.snackBarMessage("Novo Check in realizado");
          }
          this.evaluateDataUpdate({ msg: null });
        }
      } else {
        this.evaluateDataUpdate({ msg: null });
      }
    });
  }

  // evaluateDataUpdate() {
  //   if (
  //     this.trigger?.menuOpen === false &&
  //     this.reloadingDataSource === false &&
  //     this.pauseRefreshOnWsMessage === false
  //   ) {
  //     // console.log("Atualizado via ws");
  //     this.loadCheckins();
  //   } else {
  //     // console.log("Atualização bloqueada");
  //     this.needsRefreshOnWsMessage = true;
  //   }
  // }
  evaluateDataUpdate(wsMsg: any) {
    if (
      // this.trigger?.menuOpen === false &&
      this.reloadingDataSource === false &&
      this.pauseRefreshOnWsMessage === false
    ) {
      console.log("Atualizado via ws");
      this.loadCheckins();
      if (wsMsg.user_id != this.account.id) {
        if (wsMsg.msg) {
          this.snackBarMessage(wsMsg.msg);
        } else {
          this.snackBarMessage("Check in atualizado");
        }
      }
    } else {
      console.log("Atualização bloqueada");
      this.needsRefreshOnWsMessage = true;
    }
  }

  //Adiciona o Observer apenas uma vez
  paginatorObserver() {
    let actualPage: any;
    if (this.status && this.status != "") {
    }
    /// Ao utilizar a busca
    this.searchFilterSubscription = this.searchFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap(() => {
        actualPage = this.actualPage;
        this.searchingProcess();
        const pageOptions =
          this.account.system_client.resource.environment.pages[
            this.actualPage!
          ];
        let listStatus = pageOptions.with_status.join("|");
        const options = {
          orderBy: this.orderBy(this.sort?.active || "created_at", pageOptions),
          sortedBy: this.sort?.direction || "ASC",
          pageSize: this.paginator?.pageSize || 10,
          search: this.searchFilter.value || "",
          with_status: this.filterStatusSelected.value || listStatus,
          carrier_id: this.filterCarrierSelected.value || "",
          operation_id: this.filterOperationSelected.value || "",
          suboperation: this.filterSubOperationSelected.value || "",
          motorista_destino: this.filterDestinySelected.value || "",
          dock_space: this.selectedDocks ? this.selectedDocks.join("|") : "",
        };
        if (pageOptions.with_operations) {
          Object.assign(options, {
            with_operations: pageOptions.with_operations.join("|"),
          });
        }
        if (this.dynamic_filter) {
          this.updateFilterList(this.btnFilterList);
          return observableOf({ total: 0, per_page: 0, data: [] });
        } else {
          return this.checkinService.checkins(this.endpoint, options).pipe(
            catchError(() => {
              this.searching = false;
              return observableOf({ total: 0, per_page: 0, data: [] });
            }),
          );
        }
      }),
      map((response: any) => {
        this.paginatorLength = response.total;
        this.paginatorPageSize = response.per_page;
        return response.data;
      }),
    ) as Subject<any[]>;
    this.searchFilterSubscription.subscribe((data) => {
      this.setDataSource(data, actualPage);
      this.driverCheckinIdList = data.map((item: any) => item.id);
    });

    // Buscar alternativa???
    this.filterDestinySelected.valueChanges.subscribe((result: any) =>
      this.searchFilterSubscription.next(result),
    );
    if (this.sort) {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
        .pipe(
          startWith({}),
          switchMap(() => {
            actualPage = this.actualPage;
            this.searchingProcess();
            const pageOptions =
              this.account.system_client.resource.environment.pages[
                this.actualPage!
              ];
            let listStatus = pageOptions.with_status.join("|");
            this.optionsFilter.pageSize = this.paginator.pageSize
              ? this.paginator.pageSize
              : 10;
            const options = {
              orderBy: this.orderBy(
                this.sort?.active || "created_at",
                pageOptions,
              ),
              sortedBy: this.sort?.direction || "ASC",
              pageSize: this.paginator?.pageSize || 10,
              page: (this.paginator?.pageIndex || 0) + 1,
              with: "carrier",
              search: this.searchFilter.value || "",
              with_status: this.filterStatusSelected.value || listStatus,
              carrier_id: this.filterCarrierSelected.value || "",
              operation_id: this.filterOperationSelected.value || "",
              suboperation: this.filterSubOperationSelected.value || "",
              motorista_destino: this.filterDestinySelected.value || "",
              dock_space: this.selectedDocks
                ? this.selectedDocks.join("|")
                : "",
            };

            if (pageOptions.with_operations) {
              Object.assign(options, {
                with_operations: pageOptions.with_operations.join("|"),
              });
            }
            if (this.dynamic_filter) {
              this.updateFilterList(this.btnFilterList);
              return observableOf({ total: 0, per_page: 0, data: [] });
            } else {
              return this.checkinService.checkins(this.endpoint, options).pipe(
                catchError(() => {
                  this.searching = false;
                  return observableOf({ total: 0, per_page: 0, data: [] });
                }),
              );
            }
          }),
          tap((response: Response | any) => {
            if (!response.data.length && this.paginator.hasPreviousPage()) {
              this.paginator.previousPage();
            }

            this.paginatorLength = response.total;
            this.paginatorPageSize = response.per_page;
          }),
          map((response: Response | any) => {
            return response.data;
          }),
        )
        .subscribe((data) => {
          this.setDataSource(data, actualPage);
          this.driverCheckinIdList = data.map((item: any) => item.id);
        });
    }
  }

  @HostListener("window:resize", [])
  private onResize() {
    this.checkSizeScreen();
  }

  // Alteração solicitada por Jandira, re-adicionar filtro independente da resolução
  // Nota Marcos Araújo: Favor parametrizar a visão mobile para Jandira
  checkSizeScreen() {
    if (window.screen.width > 768) {
      this.isWeb = true;
    } else {
      this.isWeb = true;
    }
  }

  reload(params?: any) {
    return this.reloadEvent.emit(params);
  }

  getSearch(event: any) {
    let val = event.target.value;

    this.dataSource.data = this.dataSource.data.filter((item: any) => {
      return (
        item.driver.name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
        item.resource.board_cart.toLowerCase().indexOf(val.toLowerCase()) >
          -1 ||
        item.resource.board_horse.toLowerCase().indexOf(val.toLowerCase()) > -1
      );
    });
  }

  getFilterTransporte(transp: any) {
    this.dataSource.data = this.dataSource.data.filter((item: any) => {
      return item.driver.carrier.id === transp.id;
    });
  }

  loadCheckins(clear: boolean = false): Subject<void> {
    this.reloadingDataSource = true;
    if (clear) {
      this.rawData = [];
      this.dataSource.data = [];
      this.paginator.pageIndex = 0;
      this.paginatorLength = 0;
    }

    const pageOptions = this.getPageOptions();

    let listStatus = pageOptions.with_status.join("|");

    this.searching = true;
    const options = {
      orderBy: this.orderBy(this.sort?.active || "created_at", pageOptions),
      sortedBy: this.sort.direction ? this.sort.direction : "ASC",
      pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
      page: 1,
      search: this.searchFilter.value || "",
      with_status:
        this.filterStatusSelected.value && this.filterStatusSelected.value != ""
          ? this.filterStatusSelected.value
          : listStatus,
      carrier_id: this.filterCarrierSelected.value
        ? this.filterCarrierSelected.value
        : "",
      operation_id: this.filterOperationSelected.value
        ? this.filterOperationSelected.value
        : "",
      suboperation: this.filterSubOperationSelected.value
        ? this.filterSubOperationSelected.value
        : "",
      dock_space: this.selectedDocks ? this.selectedDocks.join("|") : "",
    };

    if (pageOptions.with_operations) {
      Object.assign(options, {
        with_operations: pageOptions.with_operations.join("|"),
      });
    }

    const subject = new Subject<void>();

    this.checkinDataSubscribe = this.checkinService
      .checkins(this.endpoint, options)
      .pipe(
        debounceTime(500),
        map((response: any) => {
          this.paginator.pageIndex = 0;
          this.paginatorLength = response.total;
          this.paginatorPageSize = response.per_page;
          subject.next();
          subject.complete();

          this.rawData.forEach((rawRow: any) => {
            const row = response.data.find(
              (row: { id: any }) => row.id === rawRow.id,
            );
            if (!!row) {
              if (
                JSON.stringify(row) !== JSON.stringify(rawRow) &&
                rawRow.id === this.selectedCheckinID
              ) {
                this.dialog.closeAll();
              }
            } else if (rawRow.id === this.selectedCheckinID) {
              this.dialog.closeAll();
            }
          });
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          subject.next();
          subject.complete();
          return observableOf([]);
        }),
      )
      .subscribe((data) => {
        this.setDataSource(data, this.actualPage);
        this.driverCheckinIdList = data.map((item: any) => item.id);
        this.reloadingDataSource = false;
        this.pauseRefreshOnWsMessage = false;
      });

    return subject;
  }

  showStatus(resource: any) {
    if (this.listStatus.length > 0 && this.allDataAvailable) {
      const st = this.listStatus.filter(
        (item: any) => item.id === resource.status,
      );
      if (resource.status === "on_dock") {
        return this.getDockName(resource.dock_space_id, resource.department_id);
      } else {
        return st[0].alternate_name;
      }
    }
  }

  getPageOptions(): any {
    return (
      this.account?.system_client?.resource?.environment?.pages[
        this.actualPage!
      ] || {}
    );
  }

  getDockName(id: number, department: number) {
    if (id >= 0) {
      if (
        this.account.department_id?.length > 1 &&
        department &&
        this.account.system_client.resource.environment.docks_shared === false
      ) {
        let result = this.dockSpacesFilterList
          ?.map((i: any) => i.docks)
          .flat()
          .find((dp: any) => dp.department_id == department && dp.id == id);
        return result?.name || "Doca não localizada";
      } else {
        if (
          this.account.department_id &&
          this.account.department_id?.length > 1 &&
          this.account.system_client.resource.environment.docks_shared === false
        ) {
          const dn = this.dockSpacesFilterList[
            this.account.department_id
          ]?.filter((item: any) => item.id === id);
          if (dn.length > 0) {
            return dn[0].name || "Doca não localizada";
          }
        } else {
          const dn = this.dockSpaces?.filter((item: any) => item.id === id);
          if (dn.length > 0) {
            return dn[0].name || "Doca não localizada";
          }
        }
      }
    }
  }

  filterPageStatus(status: any) {
    let with_status =
      this.account.system_client.resource.environment.pages[this.actualPage!][
        "with_status"
      ];

    let result = status.filter((item: any) => {
      if (with_status.includes(item.id)) return item;
    });
    return result;
  }

  getDockSpaces() {
    // Pega os registros do novo módulo de gestão de docas
    this.dockManagementService.index().subscribe((r: any) => {
      this.dockSpaces = r.data;
      //Se cliente possuir filtro de docas para essa tela, chama a função que gera a lista de docas para o filtro
      if (this.departments) {
        //Verifica se possui mais de 1 departamento, se sim popula dockSpacesFilterList
        //Com docas separadas primeiro por departamentos, caso possua apenas 1 departamento
        //dockSpacesFilterList recebe todas as docas que possuem o mesmo department_id
        if (this.departments.length > 1) {
          this.dockSpacesFilterList = JSON.parse(
            JSON.stringify(this.departments),
          );
          this.dockSpacesFilterList.forEach((item: any) => {
            item["docks"] = this.dockSpaces.filter(
              (ft: any) => ft.department_id === item.id,
            );
          });
        } else {
          this.dockSpacesFilterList = this.dockSpaces.filter(
            (ft: any) => ft.department_id === this.departments[0].id,
          );
        }
      }
    });
  }

  changeStatus(item: any, action: any) {
    // console.log("change status");
    // console.log(action);
    this.pauseRefreshOnWsMessage = true;
    const preData = {
      id: item.id,
      status: action.status,
    };

    const data: any = Object.assign(
      {
        change_key: action.change_key,
      },
      preData,
    );

    const execAction = async () => {
      if (action.component === "confirm") {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          data: {
            title: "Confirmar Ação!",
            message: action.dialogue_message,
            btnConfirm: action.btnConfirm ? action.btnConfirm : "Ok",
            btnCancel: action.btnCancel ? action.btnCancel : "Fechar",
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(data, action);
          } else {
            this.loadCheckins();
          }
        });
      }

      if (action.component === "direction") {
        if (!item.hasOwnProperty("event")) {
          item.event = { resource: { external_event_id: null } };
        }
        const dialogRef = this.dialog.open(CheckinDriverParkingLotComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (data) {
            this.updateStatus(res, action);
          }
        });
      }

      //Direcionar com opção de multiplos patios ou docas
      if (action.component === "directionMultiplePark") {
        const dialogRef = this.dialog.open(DirectDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
            department_id: item.resource.department_id,
            dock_space_id: item.resource.dock_space_id,
            vehicle_type: item.resource.vehicle_type,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            if (action.without_status === true) {
              // console.log("updateField")
              delete res.status;
              this.updateField(res, action);
              this.loadCheckins();
            } else {
              // console.log("updateStatus")

              this.updateStatus(res, action);
              this.loadCheckins();
            }
          }
        });
      }

      //Direcionar com opção de multiplos patios ou docas
      if (action.component === "directionMultipleVehicleType") {
        const dialogRef = this.dialog.open(DirectDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            filterVehicleType: true,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(res, action);
          }
        });
      }

      if (action.component === "invalidateVehicleDialog") {
        const dialogRef = this.dialog.open(InvalidateVehicleDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.loadCheckins();
            this.updateStatus({
              id: res.checkin_id,
              checklist_id: res.checklist_id,
            });
          }
        });
      }

      if (action.component === "aditionalInfo") {
        const dialogRef = this.dialog.open(AditionalInfoDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            title: action.dialog_title,
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res.id) {
            this.updateStatus(res, action);
          }
        });
      }

      if (action.component === "finishOptions") {
        const dialogRef = this.dialog.open(FinishOptionsDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: true,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(res, action);
          }
        });
      }

      // Buscar alternativa para mapa
      if (action.component === "driverOnMap") {
      }

      if (action.component === "editKeys") {
        const dialogRef = this.dialog.open(EditKeysDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((data) => {
          if (data.message) {
            this.snackBar.open(data.message, "OK", {
              duration: 3000,
              horizontalPosition: "left",
              verticalPosition: "bottom",
            });
          }
          this.loadCheckins();
        });
      }

      if (action.component === "genericDialog") {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.loadCheckins();
          }
        });
      }

      if (action.component === "assignDialogTasks") {
        const dialogRef = this.dialog.open(AssignDialogTasksComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.loadCheckins();
          }
        });
      }

      if (action.component === "otherMotivesGenericDialog") {
        const dialogRef = this.dialog.open(OtherMotivesGenericDialogComponent, {
          panelClass: ["dialog-medium"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            // refresh: () => this.loadCheckins(),
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.updateStatus(res, action);
          } else {
            this.loadCheckins();
          }
        });
      }

      if (action.component === "checklistDynamicFormComponent") {
        const formItens = await this.getScaleData(item.event.id);

        const dialogRef = this.dialog.open(ChecklistDynamicFormComponent, {
          data: {
            title: "Checklist RCO",
            message: action.dialogue_message,
            item: item,
            form: action.form_origin,
            header: action.step_header,
            id: item.id,
            formItens: formItens,
          },
        });
        dialogRef.afterClosed().subscribe((res) => {
          this.loadCheckins();
        });
      }

      if (action.component === "changeOperation") {
        const dialogRef = this.dialog.open(DialogChangeOperation, {
          data: {
            title: "Escolha a operação desejada",
            message: action.dialogue_message,
            item: item,
            id: item.id,
          },
        });
        data["changeOperation"] = true;

        dialogRef.afterClosed().subscribe((res) => {
          this.loadCheckins();
        });
      }

      if (action.component === "releaseDock") {
        const dialogRef = this.dialog.open(ReleaseDockDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            title: "Confirmar Ação!",
            message: action.dialogue_message,
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((data) => {
          this.loadCheckins();
        });
      }

      if (action.component === "infoForm") {
        const dialogRef = this.dialog.open(InfoFormDialogComponentComponent, {
          panelClass: ["dialog-medium"],
          disableClose: false,
          data: {
            title: action.dialog_title,
            message: action.dialogue_message,
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res == 200) {
            this.loadCheckins();
          }
        });
      }

      if (action.component === "dynamicForm") {
        this.getDynamicForm(action, item);
      }

      if (action.component === "showInfo") {
        const dialogRef = this.dialog.open(ShowInfoDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            info_fields: action.info_fields,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {});
      }

      if (action.component === "showDocuments") {
        const dialogRef = this.dialog.open(DocumentsDialogComponent, {
          panelClass: ["dialog-small"],
          disableClose: false,
          data: {
            item: JSON.parse(JSON.stringify(item)),
            action: action,
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          this.loadCheckins();
        });
      }

      if (action.component === "ScheduleDinamicFormDialog") {
        const dialogRef = this.dialog.open(ScheduleDinamicFormDialogComponent, {
          panelClass: ["dialog-medium"],
          disableClose: false,
          data: {
            schedule: {
              id: item.event.id,
              ...JSON.parse(JSON.stringify(item.event.resource)),
            },
            action: null,
            page: "checkin",
            id: item.id,
          },
        });

        dialogRef.componentInstance.forkJoinDone$
          .pipe(debounceTime(1000))
          .subscribe(() => {
            dialogRef.componentInstance.config_scheduling = {
              edit: false,
              create: false,
              delete: false,
            };

            dialogRef.componentInstance.scheduleActions = [];

            dialogRef.componentInstance.form.disable();
          });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.loadCheckins();
          }
        });
      }

      if (action.component === "unlinkDriver") {
        const dialogRef = this.dialog.open(DialogUnlinkDriverComponent, {
          panelClass: ["dialog-small"],
          data: {
            title: "Desvincular Motorista",
            message: action.dialogue_message,
            item: item,
            id: item.id,
          },
        });
        data["unlinkDriver"] = true;

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.loadCheckins();
          }
        });
      }

      // Avaliar se pode ser apagado
      if (action.component === "valetConfirm") {
        const dialogRef = this.dialog.open(DialogConfirmValetComponent, {
          panelClass: ["dialog-small"],
          data: {
            title: "Confirmar Ação",
            message: item.resource.valet_mode_activated
              ? "Deseja desabilitar o 'Modo Manobra'?"
              : "Deseja habilitar o 'Modo Manobra'?",
            item: {
              id: item.resource.id,
              valetMode: item.resource.valet_mode_activated,
            },
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.loadCheckins();
          }
        });
      }

      if (action.component === "linkDriver") {
        const dialogRef = this.dialog.open(DialogLinkDriverComponent, {
          panelClass: ["dialog-small"],
          data: {
            title: "Vincular motorista",
            message: action.dialogue_message,
            item: item,
            id: item.id,
          },
        });
        data["linkDriver"] = true;

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.loadCheckins();
          }
        });
      }

      if (action.component === "driverCkeckout") {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          data: {
            title: "Confirmar Ação!",
            message: "Realmente deseja fazer Checkout desse motorista?",
            btnConfirm: action.btnConfirm ? action.btnConfirm : "Ok",
            btnCancel: action.btnCancel ? action.btnCancel : "Fechar",
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.truckerCheckout(data, action);
          }
        });
      }

      if (action.component === "assignQualcommDevice") {
        const dialogRef = this.dialog.open(AssignQualcommDeviceComponent, {
          panelClass: ["dialog-small"],
          data: {
            checkin_id: item.id,
            cq: "qualcomm_devices",
            device: item.resource.device,
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.loadCheckins();
          }
        });
      }

      if (action.component === "getpdf") {
        console.log("GET PDF");
        this.print(item.id);
      }

      if (action.component === "removeQualcommDevice") {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          panelClass: ["dialog-small"],
          data: {
            title: "Confirmar Ação!",
            message: "Tem certeza que deseja 'Recuperar Rastreador'?",
            btnConfirm: "SIM",
            btnCancel: "NÃO",
            id: item.id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            if (item.resource.hasOwnProperty("device")) {
              let device = item.resource.device;
              device.linked = false;
              device.unlinked_by = this.account.id;
              device.unlinked_at = new Date().toISOString();
              let opt: any = {
                status: "device_unlinked",
                device_hystory: device,
                device: device,
              };

              this.checkinService
                .updateStatus({ id: item.id, ...opt })
                .subscribe((r: any) => {
                  this.loadCheckins();
                });
            } else {
              console.log("Não existe dispositivo vinculado");
            }
          }
        });
      }

      if (action.component === "weightControl") {
        this.getWeightForm(action, item);
      }

      if (action.component === "flowControl") {
        this.getFlowControl(action, item);
      }

      if (action.component === "exceptionControl") {
        this.getExceptionControl(action, item);
      }

      if (
        (<string>action.component || "").toLowerCase() === "microservicedialog"
      ) {
        const dialogRef = this.dialog
          .open(MicroserviceDialog, {
            data: {
              data: item,
              ...action.micro_service,
              id: item.id,
            },
            ...(action.dialog_config || {}),
          })
          .afterClosed()
          .subscribe((res) => {});
      }

      if (
        <string>action?.component?.toLowerCase() === "journeytimelinestatus"
      ) {
        const events =
          this.listStatus
            ?.map((status: any) => {
              const timestamp = item.resource?.[`${status.id}_created_at`];
              const description = `Criardo por: ${item.resource?.[`${status.id}_created_by`] ?? "Não informado."}`;

              if (!timestamp) return null;

              return {
                id: status.id,
                timestamp: new Date(timestamp),
                title: status.name,
                description,
                itemPosition: "",
              };
            })
            ?.filter((item: any) => item) || [];

        const data = {
          checkin_id: item.id,
          operation_name: this.nameOfOperation(
            item.resource.operation || item.resource.operation_id,
          ),
          driver_name: item.driver_name,
          device_linked: item.hasOwnProperty("device_linked")
            ? item.device_linked
            : null,
          carrier_name: item.carrier_name,
          board_horse: item.resource.hasOwnProperty("board_horse")
            ? item.resource.board_horse
            : null,
          board_cart: item.resource.hasOwnProperty("board_cart")
            ? item.resource.board_cart
            : null,
          events,
        };
        JourneyStatusTimelineRender.open(data, action.props);
      }

      if (action.component === "callToAction") {
        const dialogRef = this.dialog.open(CallToActionConfirmComponent, {
          data: {
            title: "Confirmar Ação!",
            message: action.dialogue_message,
            btnConfirm: action.btnConfirm ? action.btnConfirm : "Ok",
            btnCancel: action.btnCancel ? action.btnCancel : "Fechar",
            id: item.id,
            component_props: action.component_props,
            status: action.status,
            device_id: item.resource.device.device_id,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            // console.log("fechoou");
            // this.updateStatus(data, action);
            this.snackBar.open(res.message, "OK", {
              duration: 3000,
              horizontalPosition: "left",
              verticalPosition: "bottom",
            });
          }
        });
      }
    };

    if (action.open_dialogue) {
      if (
        item.resource.valet_mode_activated == true &&
        action.component != "valetConfirm"
      ) {
        this.moduleValetDriversAvailable(<number>item.id).subscribe((bool) => {
          bool && execAction();
        });
      } else {
        execAction();
      }
    }
  }

  truckerCheckout(data: any, action?: any) {
    this.checkinService.truckerCheckout(data).subscribe({
      next: (r: any) => {
        this.loadCheckins();
        if (action) {
          this.snackBar.open("Checkout realizado com sucesso", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      },
      error: (e: any) => {
        this.loadCheckins();
      },
    });
  }

  private moduleValetDriversAvailable(checkin_id: number): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.checkinService
        .verifyAvalaibleValetDriver(checkin_id)
        .pipe(map((x) => x?.data))
        .subscribe((response: any[] = []) => {
          if (!response || response.length == 0) {
            this.dialog
              .open(DialogConfirmComponent, {
                data: {
                  title: "Confirmar Ação!",
                  message:
                    "Nenhum manobrista disponível no momento, deseja registrar a 'Manobra'?",
                  btnConfirm: "Prosseguir",
                  btnCancel: "Cancelar",
                  id: checkin_id,
                },
              })
              .afterClosed()
              .subscribe((result) => observer.next(result == true));
          } else if (response instanceof Object) {
            observer.next(true);
          } else {
            observer.error(
              new Error("Não foi possível prosseguir com a ação de manobra."),
            );
          }
        });
    });
  }

  updateStatus(data: any, action?: any) {
    this.block_actions = true;
    let rowValue = this.rawData.find((x: any) => x.id == data.id);
    let snackBarMessage =
      "Status alterado para " + (action || { name: "" }).name;

    if (
      this.account.system_client.resource.environment.module_valet &&
      rowValue.resource.valet_mode_activated
    ) {
      data.action_payload = {
        ...data,
        department_id: rowValue.resource.department_id,
      };

      if (action && action.module_valet_action)
        data.module_valet_action = action.module_valet_action;

      snackBarMessage =
        "Manobra registrada para " + (action || { name: "" }).name;
    }

    this.checkinService.updateStatus(data).subscribe({
      next: (r: any) => {
        this.loadCheckins();
        if (action) {
          this.block_actions = false;
          this.snackBar.open(snackBarMessage, "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      },
      error: (err: any) => {
        this.block_actions = false;
        if (err.status === 422) {
          this.dialog.open(DialogAlertComponent, {
            data: {
              title: "Ops! Tem algo errado",
              message: Object.values(err.error.errors)
                .map((item: any) => item.join("\n"))
                .join("\n"),
            },
          });
        } else {
          this.dialog.open(DialogAlertComponent, {
            data: {
              title: "Ops! Tem algo errado",
              message:
                "Desculpe, tivemos um erro interno. Se o erro permanecer contate nosso suporte.",
            },
          });
        }
        this.loadCheckins();
      },
    });
  }

  updateField(data: any, action?: any) {
    this.block_actions = true;
    this.checkinService.updateField("checkins-update-field", data).subscribe({
      next: (r: any) => {
        this.loadCheckins();
        if (action) {
          this.block_actions = false;
          this.snackBar.open(action.name || "Check in atualizado.", "OK", {
            duration: 3000,
            horizontalPosition: "left",
            verticalPosition: "bottom",
          });
        }
      },
      error: (e: any) => {
        this.block_actions = false;
        this.loadCheckins();
      },
    });
  }

  disableBtn(status: any, action: any, rowValue: any) {
    const array_status = action.active_on_status;
    const deactivate = action.deactivated_when;
    const activated = action.activated_when;

    const st = array_status?.filter((item: any) => {
      if (status === item || item == "*") {
        if (
          deactivate &&
          rowValue &&
          (rowValue.resource || { resource: {} })[deactivate.key_has_value]
        )
          return false;

        if (activated && rowValue)
          return !!(rowValue.resource || { resource: {} })[
            activated.key_has_value
          ];

        return true;
      } else {
        return false;
      }
    });

    // Chama outra função para habilitar ou não a ação (A função deve retornar um Boolean), Exemplo:
    // Dentro das actions devem conter os seguintes paramentros:
    // "transform_field": true, --Condição para habilitar.
    // "transform_method": "showOperation", --Nome da função criada.
    // "transform_params": ["resource.operation"] --Parametro que a função vai receber.

    if (action.transform_field) {
      const params = this.prepareParams(rowValue, action.transform_params);
      const thisScope: any =
        this[action.transform_method as keyof typeof this] ||
        function () {
          return null;
        };
      return thisScope.apply(this, [params]);
    }

    //Condições Engate e desengate
    if (action.component === "unlinkDriver") {
      if (rowValue.resource.cpf && rowValue.resource.driver_id) {
        return false;
      }
    }

    if (action.component === "linkDriver") {
      if (!rowValue.resource.cpf && !rowValue.resource.driver_id) {
        return false;
      }
    }

    //Condições Manobrista
    if (this.account.system_client.resource.environment.module_valet) {
      if (rowValue.resource.valet_mode_activated === true) {
        if (rowValue.valet_journey) {
          const valetJourneyStatus = <string>(
            rowValue.valet_journey.resource.parking_status
          );
          if (valetJourneyStatus == "started") return true;

          if (action.component === "valetConfirm") return false;

          if (valetJourneyStatus == "waiting") return true;
        }

        if (action.component === "valetConfirm") return false;

        if (action.module_valet_action) return !(st && st.length > 0);

        return true;
      } else if (action.component === "valetConfirm") {
        return false;
      }
    }

    //Condições engate e desengate
    if (
      action.hasOwnProperty("disableUnlink") &&
      action.disableUnlink != false &&
      this.account.system_client.resource.environment.trip_hitch &&
      (rowValue.last_trucker_checkin == null ||
        (!rowValue.resource.board_cart && !rowValue.resource.board_horse) ||
        !rowValue.resource.trucker_checkin_id)
    ) {
      return true;
    }

    if (st) return !(st.length > 0);
    return true;
  }

  getStatusColor(status: any) {
    if (this.listStatus.length > 0 && this.allDataAvailable) {
      const st = this.listStatus?.filter((item: any) => item.id === status);
      return st[0]["color"];
    } else {
      ("");
    }
  }

  weightConditionForTigre(resource: any) {
    try {
      if (
        resource[0]?.pes_ini_realizada?.pesagem_inicial &&
        resource[0]?.pesagem_final_realizada?.peso_final
      ) {
        return false;
      } else if (
        !resource[0]?.pes_ini_realizada?.pesagem_inicial &&
        !resource[0]?.pesagem_final_realizada?.peso_final
      ) {
        return false;
      } else {
        return true;
      }
    } catch {
      return false;
    }
  }

  showOnlyIfOperationMatch(element: any, action: any) {
    if (action.show_on_operation) {
      if (
        action.show_on_operation.includes(element.resource.operation) ||
        action.show_on_operation.includes("*")
      ) {
        return "show";
      } else {
        return "none";
      }
    } else {
      return "none";
    }
  }

  showOnlyIfValetIsCorrect(element: any, action: any) {
    if (action.component === "valetConfirm") {
      if (element.resource.valet_mode_activated) {
        action.name = "Desabilitar manobra";
        action.valetButtonMode = "deactivate";
        return "show";
      } else {
        action.name = "Habilitar manobra";
        action.valetButtonMode = "activate";
        return "show";
      }
    } else if (action.show_on_operation) {
      if (
        action.show_on_operation.includes(
          +element.resource.operation || +element.resource.operation_id,
        )
      ) {
        return "show";
      } else {
        return "none";
      }
    } else {
      return "none";
    }
  }

  filterEnabled(filter: string) {
    const filters =
      this.account.system_client.resource.environment.pages[this.actualPage!][
        "data_filters"
      ];
    this.filterWidth = 100 / filters.length + "%";
    const ft = filters.filter((item: any) => item === filter);
    return ft.length > 0;
  }

  labelFilterEnabled() {
    this.filterLabels =
      this.account.system_client.resource.environment.pages[this.actualPage!][
        "data_filters_label"
      ];
  }

  //Cria os controls para cada filtro de dados do agendamento
  createEventFilterControls(): void {
    this.eventFiltersForm = this.fb.group({
      eventFilters: this.fb.array([]),
    });

    const evfilters =
      this.account.system_client.resource.environment.pages[this.actualPage][
        "data_filters"
      ];

    //Se existir registros em data_filters para a página atual que comecem com 'events_'
    //Eles são inicializados e recebem o subscription de valueChanges para que façam a busca quando seu campo for modificado
    this.eventFilterList = evfilters
      .filter(
        (item: any) => typeof item === "string" && item.startsWith("events_"),
      )
      .map((item: string) => item);

    const eventFiltersArray = this.eventFiltersForm.get(
      "eventFilters",
    ) as FormArray;
    this.eventFilterList.forEach((filter) => {
      const control = this.fb.control("");
      eventFiltersArray.push(control);

      const subscription = control.valueChanges
        .pipe(debounceTime(650))
        .subscribe((value) => {
          this.getFilterEvents(filter, value);
        });

      this.eventFiltersSubscription.push(subscription);
    });
  }

  getFilterEvents(filter: string, value: any) {
    if (filter) {
      const actualPage = this.actualPage;

      this.searchingProcess();

      //Remove o filtro de optionsFilter se ele já existir e a nova value estiver vazia
      if (this.optionsFilter.hasOwnProperty(filter) && !value) {
        delete this.optionsFilter[filter];
      }

      this.optionsFilter[filter] = value;

      this.checkinDataSubscribe = this.checkinService
        .checkins(this.endpoint, this.optionsFilter)
        .pipe(
          map((response: any) => {
            this.paginator.pageIndex = 0;
            this.paginatorLength = response.total;
            this.paginatorPageSize = response.per_page;
            return response.data;
          }),
          catchError(() => {
            this.searching = false;
            return observableOf([]);
          }),
        )
        .subscribe((data: any[]) => {
          this.setDataSource(data, actualPage);
          this.driverCheckinIdList = data.map((item: any) => item.id);
        });
    }
  }

  getCarrier() {
    const options = {
      orderBy: "name",
      sortedBy: "ASC",
      page: 1,
      pageSize: 1000,
    };

    this.carrierService.index(options).subscribe((data) => {
      this.transportes = (data as any).data;
    });
  }

  getActions() {
    this.listAction = this.listActionRef[this.actualPage!];
  }

  getOperations(department_id?: number | string) {
    if (!department_id) {
      this.operation_id = "";
      this.optionsFilter.operation_id = "";
      this.filterOperationSelected.setValue("", { emitEvent: false });
    }
    if (!department_id && this.account.department_id?.length == 1) {
      department_id = this.account.department_id[0];
    }

    if (department_id) {
      this.operations = this.operations.filter((item: any) => {
        return item.department_id === department_id;
      });
    }
  }

  getSuboperations(operation_id?: any) {
    if (!operation_id) {
      this.suboperation = "";
      this.optionsFilter.suboperation = "";
      this.filterSubOperationSelected.setValue("", { emitEvent: false });
    }
    if (operation_id) {
      if (this.suboperationsList.length > 0) {
        this.suboperations = this.suboperationsList;
      }
      this.suboperations = this.suboperations.filter((item: any) => {
        return item.operation_id === operation_id;
      });
    }
  }

  nameOfOperation(id: number) {
    if (id && this.operations) {
      const op = this.operations.filter((item: any) => {
        return item.id === id;
      });
      if (op.length > 0) return op[0].name;
      else return "";
    }
  }

  // Busca todos os registros necessários em support data de uma vez só, trazendo tudo na mesma API
  getSupportData() {
    const options = [
      "actions",
      "status",
      "operation_type",
      "suboperation_type",
      "vehicle_type",
      "operators",
      "category_list",
      "valet_status",
      "departments",
    ];
    this.supportDataService
      .multi({ show: options.join(",") })
      .subscribe((r: any) => {
        if (r.data.hasOwnProperty("actions")) {
          this.listActionRef = r.data.actions;
          this.getActions();
        }
        if (r.data.hasOwnProperty("status")) {
          this.listStatus = r.data.status;
        }
        if (r.data.hasOwnProperty("operation_type")) {
          for (let i = 0; i < r.data.operation_type.length; i++) {
            if (r.data.operation_type[i].visible) {
              this.operations.push(r.data.operation_type[i]);
            }
          }
        }
        if (r.data.hasOwnProperty("suboperation_type")) {
          this.suboperations = r.data.suboperation_type;
          this.suboperationsList = r.data.suboperation_type;
        }
        if (r.data.hasOwnProperty("vehicle_type")) {
          this.vehicle_types = r.data.vehicle_type;
          this.vehicleTypesLoaded = true;
        }
        if (r.data.hasOwnProperty("operators")) {
          this.operatorAll = r.data.operators;
        }
        if (r.data.hasOwnProperty("category_list")) {
          this.categories = r.data.category_list;
        }
        if (r.data.hasOwnProperty("valet_status")) {
          this.valetStatusAll = r.data.valet_status;
        }
        if (r.data.hasOwnProperty("departments")) {
          this.departments = JSON.parse(
            JSON.stringify(r.data.departments),
          ).filter((item: any) => {
            return this.account.department_id?.indexOf(item.id) > -1;
          });
        }

        // Após trazer todos os resultados de referência...
        this.getDockSpaces();
        this.loadCheckins(true);
      });
  }

  clearFilter(event?: any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
      //removido, já possui um observer no campo searchFilter que irá executar nova pesquisa
      //this.loadCheckins();
    }
  }

  //Limpa o filtro de dado do agendamento especificado
  clearEventFilter(eventFilterIndex?: any) {
    if (
      this.eventFiltersForm.get("eventFilters")?.get(eventFilterIndex)?.value
    ) {
      this.eventFiltersForm
        .get("eventFilters")
        ?.get(eventFilterIndex)
        ?.setValue("");
    }
  }

  // Buscar alternativa para mapa
  driverInMap(event: any, item: any = {}) {
    const dialogRef = this.dialog.open(DetailsMapsComponent, {
      panelClass: ["dialog-large"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {});
  }

  getFilterStatus(status: any) {
    const actualPage = this.actualPage;
    const pageOptions =
      this.account.system_client.resource.environment.pages[this.actualPage!];
    this.status = status || pageOptions.with_status.join("|");
    this.optionsFilter.with_status = this.status;
    this.optionsFilter.search = `${this.searchFilter.value}` || "";

    this.searchingProcess();
    this.checkinDataSubscribe = this.checkinService
      .checkins(this.endpoint, this.optionsFilter)
      .pipe(
        map((response: any) => {
          this.paginator.pageIndex = 0;
          this.paginatorLength = response.total;
          this.paginatorPageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data: any[]) => {
        this.setDataSource(data, actualPage);
        this.driverCheckinIdList = data.map((item: any) => item.id);
      });
  }

  getFilterDepartment(department_id: number | string) {
    const pageOptions =
      this.account.system_client.resource.environment.pages[this.actualPage!];
    let actualPage = this.actualPage;
    this.department_id = department_id;
    this.getOperations(department_id);
    this.showOptionsOperations = true;
    this.optionsFilter.with_status = this.status
      ? this.status
      : pageOptions.with_status.join("|");

    this.optionsFilter.departments_id = department_id;

    this.searchingProcess();
    this.checkinDataSubscribe = this.checkinService
      .checkins(this.endpoint, this.optionsFilter)
      .pipe(
        map((response: any) => {
          this.paginator.pageIndex = 0;
          this.paginatorLength = response.total;
          this.paginatorPageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data: any[]) => {
        this.setDataSource(data, actualPage);
        this.driverCheckinIdList = data.map((item: any) => item.id);
      });
  }

  getFilterOperation(operation_id: any) {
    const pageOptions =
      this.account.system_client.resource.environment.pages[this.actualPage!];
    const actualPage = this.actualPage;
    this.operation_id = operation_id;

    this.getSuboperations(operation_id);

    this.optionsFilter.with_status = this.status
      ? this.status
      : pageOptions.with_status.join("|");
    this.optionsFilter.operation_id = operation_id;
    this.optionsFilter.search = `${this.searchFilter.value}` || "";

    if (
      (!operation_id || operation_id == "") &&
      pageOptions.with_operations instanceof Array
    ) {
      this.optionsFilter.with_operations =
        pageOptions.with_operations.join("|");
    }

    this.searchingProcess();
    this.checkinDataSubscribe = this.checkinService
      .checkins(this.endpoint, this.optionsFilter)
      .pipe(
        map((response: any) => {
          this.paginator.pageIndex = 0;
          this.paginatorLength = response.total;
          this.paginatorPageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data: any[]) => {
        this.setDataSource(data, actualPage);
        this.driverCheckinIdList = data.map((item: any) => item.id);
      });

    if (operation_id) {
      this.filterSubOperationSelected.enable();
    } else {
      this.subOperationSelected = [];
      this.filterSubOperationSelected.reset();
      this.filterSubOperationSelected.disable();
    }
  }

  getFilterSubOperation() {
    const pageOptions =
      this.account.system_client.resource.environment.pages[this.actualPage!];
    const actualPage = this.actualPage;
    this.suboperation = this.subOperationSelected;
    this.optionsFilter.with_status = this.status
      ? this.status
      : pageOptions.with_status.join("|");
    this.optionsFilter.suboperation = this.subOperationSelected.join("|");
    this.optionsFilter.search = `${this.searchFilter.value}` || "";

    this.searchingProcess();
    this.checkinDataSubscribe = this.checkinService
      .checkins(this.endpoint, this.optionsFilter)
      .pipe(
        map((response: any) => {
          this.paginator.pageIndex = 0;
          this.paginatorLength = response.total;
          this.paginatorPageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data: any[]) => {
        this.setDataSource(data, actualPage);
        this.driverCheckinIdList = data.map((item: any) => item.id);
      });
  }

  setSubOperationFilterOptions(st_id: any) {
    //Verifica se st_id já existe no array subOperationSelected
    //Se sim então o usuário está desmarcando a opção, então ela é removida do array
    //Caso ao contrário é adicionada ao array
    if (this.subOperationSelected.includes(st_id)) {
      this.subOperationSelected = this.subOperationSelected.filter(
        (item: any) => item !== st_id,
      );
    } else {
      this.subOperationSelected.push(st_id);
    }
  }

  clearMultiselectFilter(param: any) {
    if (param === "suboperation") {
      this.filterSubOperationSelected.reset();
      this.subOperationSelected = [];
    } else if (param === "dock") {
      this.filterDockSpaceSelected.reset();
      this.selectedDocks = [];
    }
  }

  getFilterCarrier(carrier_id: number | string) {
    const pageOptions =
      this.account.system_client.resource.environment.pages[this.actualPage!];
    const actualPage = this.actualPage;

    if (pageOptions.with_operations) {
      Object.assign(this.optionsFilter, {
        with_operations: pageOptions.with_operations.join("|"),
      });
    }
    this.carrier_id = carrier_id;
    this.optionsFilter.carrier_id = carrier_id;
    this.optionsFilter.with_status = this.status
      ? this.status
      : pageOptions.with_status.join("|");
    this.optionsFilter.search = `${this.searchFilter.value}` || "";

    this.searchingProcess();
    this.checkinDataSubscribe = this.checkinService
      .checkins(this.endpoint, this.optionsFilter)
      .pipe(
        map((response: any) => {
          this.paginator.pageIndex = 0;
          this.paginatorLength = response.total;
          this.paginatorPageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data: any[]) => {
        this.setDataSource(data, actualPage);
        this.driverCheckinIdList = data.map((item: any) => item.id);
      });
  }

  getFilterDestiny(motorista_destino: any) {
    const pageOptions =
      this.account.system_client.resource.environment.pages[this.actualPage!];
    const actualPage = this.actualPage;
    this.motorista_destino = motorista_destino;

    this.optionsFilter.with_status = this.status
      ? this.status
      : pageOptions.with_status.join("|");
    this.optionsFilter.motorista_destino = motorista_destino;
    this.optionsFilter.search = `${this.searchFilter.value}` || "";

    if (
      (!motorista_destino || motorista_destino == "") &&
      pageOptions.with_operations instanceof Array
    ) {
      this.optionsFilter.with_operations =
        pageOptions.with_operations.join("|");
    }

    this.searchingProcess();
    this.checkinDataSubscribe = this.checkinService
      .checkins(this.endpoint, this.optionsFilter)
      .pipe(
        map((response: any) => {
          this.paginator.pageIndex = 0;
          this.paginatorLength = response.total;
          this.paginatorPageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data: any[]) => {
        this.setDataSource(data, actualPage);
        this.driverCheckinIdList = data.map((item: any) => item.id);
      });
  }

  //Pega o ID da doca e passa para o array de objetos selectedDocks
  //Que será utilizado pelo filtro de docas
  setDockSpaceFilterOptions(dock_id: any) {
    //Verifica se dock_id já existe no array selectedDocks
    //Se sim então o usuário está desmarcando a opção, então ela é removida do array
    //Caso ao contrário é adicionada ao array
    if (this.selectedDocks.includes(dock_id)) {
      this.selectedDocks = this.selectedDocks.filter(
        (item: any) => item !== dock_id,
      );
    } else {
      this.selectedDocks.push(dock_id);
    }
  }

  //Pega o array selectedDocks que foi processado em setDockSpaceFilterOptions
  //E passa como parametro para a chamada da API com o filtro de docas
  getFilterDockSpace() {
    const pageOptions =
      this.account.system_client.resource.environment.pages[this.actualPage!];
    const actualPage = this.actualPage;

    //Junta os parametros do array, separados por '|' para que possam ser enviados para a API com a requisição GET
    this.optionsFilter.dock_space = this.selectedDocks.join("|");

    this.optionsFilter.with_status = this.status
      ? this.status
      : pageOptions.with_status.join("|");

    this.optionsFilter.search = `${this.searchFilter.value}` || "";

    this.searchingProcess();

    this.checkinDataSubscribe = this.checkinService
      .checkins(this.endpoint, this.optionsFilter)
      .pipe(
        debounceTime(500),
        map((response: any) => {
          this.paginator.pageIndex = 0;
          this.paginatorLength = response.total;
          this.paginatorPageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data: any[]) => {
        this.setDataSource(data, actualPage);
        this.driverCheckinIdList = data.map((item: any) => item.id);
      });
  }

  //Função que retorna as colunas das páginas que ficam armazenadas na resource de system_client
  getColumns() {
    this.columns =
      this.account.system_client.resource.environment.pages[this.actualPage!][
        "columns"
      ];
    //Ordena os objetos do array columns em ordem crescente do parametro "cols" de cada coluna
    this.columns.sort((a: any, b: any) => a.cols - b.cols);
  }

  // Gera o Código A Do Formatador
  formatData(data: any) {
    let rows: any[] = [];
    let cols: any[] = [];

    if (
      this.account.system_client.resource.environment.pages[this.actualPage!]
        .action_column !== false
    ) {
      cols.push("actions");
    }

    this.columns.map((c: any) => cols.push(c.name));

    data.map((i: any) => {
      let obj: any = {};

      this.columns.map((j: any) => {
        if (j) {
          let value = this.getValue(i, j.key);

          if (j.key === "driver_phone") {
            value = this.makePhoneMask(value);
          } else if (j.key === "driver_cpf") {
            value = this.makeCpfMask(value);
          }

          obj[j.name] = {
            value: this.processColumns(value, j, i),
            name: j.name,
            format: j.format ? j.format : null,
            type: j.type,
            label: j.label,
          };
        }
      });

      obj["actions"] = {
        value: i,
        name: "actions",
        format: null,
        type: "object",
        label: "actions",
      };
      rows.push(obj);
    });
    this.cols = cols;

    return rows;
  }

  makeCpfMask(cpf: string) {
    const maskedCpf = cpf.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4",
    );

    return maskedCpf;
  }

  makePhoneMask(phone: string) {
    let maskedPhone;

    if (phone.length === 10) {
      maskedPhone = phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    } else {
      maskedPhone = phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }

    return maskedPhone;
  }

  getValue(object: object, path: any) {
    return path
      .replace(/\[/g, ".")
      .replace(/\]/g, "")
      .split(".")
      .reduce((o: any, k: any) => (o || {})[k], object);
  }

  formatDate(date: any, formatString: any) {
    let newDate = moment(date).format(formatString);
    return newDate;
  }

  // Funções chamadas dinamicamente a partir do objeto
  // de configuração das colunas, gravado no localstorage
  // ao fazer o login no sistema

  showNameViaId(event: any) {
    // ToDo: Keep this function generic
    if (event.length > 0) {
      if (!event[0]) return "";
      return (
        this.departments.filter((op: any) => op.id === parseInt(event[0]))[0]
          .name || ""
      );
    } else {
      return "";
    }
  }

  showIfBoardCartExist(params: any) {
    const checkin = params[0] ? params[0] : { resource: {} };
    const event =
      params[1] > 0 || Object.keys(params[1]).includes("resource")
        ? params[1]
        : { resource: {} };
    if (checkin.board_cart) {
      if (event.resource.operation_id == 1) {
        return event.resource.sid || "";
      } else {
        return event.resource.external_event_id || "";
      }
    } else {
      return "";
    }
  }

  showRoute(resource: any) {
    return resource[0].route[0].name;
  }

  showOperation(event: any) {
    if (event.length > 0) {
      if (!event[0]) {
        return "";
      }
      const op = this.operations.filter(
        (op: any) => op.id === parseInt(event[0]),
      );
      if (op.length > 0) {
        return op[0].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  showMaterial(event: any) {
    if (event.length > 0) {
      if (!event[0] || !event[0].extra_data.motorista_destino) return "";

      if (event[0].extra_data.motorista_destino)
        return {
          name: "",
          ...this.destinyAll.find(
            (op: any) => op.id === event[0].extra_data.motorista_destino,
          ),
        }.detail;

      return {
        name: "",
        ...this.destinyAll.find(
          (op: any) => op.id === event[0].extra_data.motorista_destino,
        ),
      }.detail;
    }
    return "";
  }

  showOperators(resource: any) {
    if (resource.length > 0) {
      if (
        !resource[0] ||
        (!resource[0].inicio_descarregamento?.operator &&
          !resource[0].inicio_carregamento?.operator)
      )
        return "";

      if (resource[0].inicio_descarregamento?.operator)
        return {
          name: "",
          ...this.operatorAll.find(
            (op: any) => op.id === resource[0].inicio_descarregamento.operator,
          ),
        }.name;
      else if (resource[0].inicio_carregamento.operator)
        return {
          name: "",
          ...this.operatorAll.find(
            (op: any) => op.id === resource[0].inicio_carregamento.operator,
          ),
        }.name;
    }
    return "";
  }

  showSubOperation(event: any) {
    if (event.length > 0) {
      if (!event[0] || (!event[0].suboperation && !event[0].suboperation_id))
        return "";

      if (event[0].suboperation_id)
        return {
          name: "",
          ...this.suboperationsList.find(
            (op: any) => op.id === event[0].suboperation_id,
          ),
        }.name;

      return {
        name: "",
        ...this.suboperationsList.find(
          (op: any) => op.id === event[0].suboperation,
        ),
      }.name;
    }

    return "";
  }

  showDestiny(event: any) {
    return [].concat(this.destinyAll).reduce(
      (o: any, item: any) => {
        if (item instanceof Object && item.id == event) {
          o.name = item.name + "; " + item["sub-area"];
        }
        return o;
      },
      { name: "N.A" },
    ).name;
  }

  showValetStatus(valet_journey: any) {
    if (valet_journey[0]) {
      if (!valet_journey[0] || !valet_journey[0].resource.parking_status)
        return "-";

      if (valet_journey[0].resource.parking_status)
        return {
          name: "",
          ...this.valetStatusAll.find(
            (st: any) => st.id === valet_journey[0].resource.parking_status,
          ),
        }.name;

      return {
        name: "",
        ...this.valetStatusAll.find(
          (st: any) => st.id === valet_journey[0].resource.parking_status,
        ),
      }.name;
    }
    return "-";
  }

  showCategory(event: any) {
    if (event.length > 0) {
      return (
        this.categories.filter((op: any) => op.id === event[0].category)[0]
          .name || ""
      );
    } else {
      return "";
    }
  }

  showVehicleType(event: any) {
    if (this.vehicleTypesLoaded) {
      if (event.length > 0 && this.vehicle_types.length > 0) {
        if (
          !event[0] ||
          !event[0].resource ||
          !event[0].resource.vehicle_type
        ) {
          return "";
        }

        const vt = this.vehicle_types.filter(
          (op: any) => op.id === event[0].resource.vehicle_type,
        );
        if (vt.length > 0) {
          return vt[0].label;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  }

  showVehicleTypeDF(vtype: any) {
    const vt = this.vehicle_types.filter((item: any) => item.id === vtype[0]);
    if (vt.length > 0) {
      return vt[0].name;
    } else {
      return "--";
    }
  }

  showVehicleTypeNoScheduling(obj: any) {
    let vehicle_type: number;
    if (this.vehicleTypesLoaded) {
      if (obj.length > 0) {
        vehicle_type = parseInt(obj[0]);
      }
      const vt = this.vehicle_types.filter(
        (item: any) => item.id === vehicle_type,
      );
      if (vt.length > 0) {
        return vt[0].label;
      } else {
        return "";
      }
    }
  }

  // Função criada para Mcassab com o intuito de renderizar os tipos de veículo corretamente indepedente de ter agendamento ou não.
  showVehicleTypeByCheckinId(id: any) {
    if (!this.vehicleTypesLoaded) return "";

    const checkin = this.rawData.find((item: any) => item.id == id[0]);
    if (!checkin) return "";

    const vehicleTypeId =
      checkin.event?.resource?.vehicle_type ?? checkin.resource?.vehicle_type;
    const vehicleType = this.vehicle_types.find(
      (op: any) => op.id == vehicleTypeId,
    );

    return vehicleType ? vehicleType.label : "";
  }

  showTrueIfKeyExist(key: string) {
    if (key) {
      if (key[0]) {
        return "Sim";
      } else {
        return "Não";
      }
    } else {
      return "Não";
    }
  }

  showId(event: any) {
    if (event.length > 0) {
      if (!event[0] || !event[0].operation_id) return "";
      if (event[0].operation_id == 1) {
        return event[0].sid || "";
      } else {
        return event[0].external_event_id || "";
      }
    } else {
      return "";
    }
  }

  showStatusColorAndName(resource: any) {
    const color = this.getStatusColor(resource[0].status);
    const name = this.showStatus(resource[0]);

    return { color, name };
  }

  showDockNumber(resource: any) {
    if (resource[0] > 0) {
      let dn = this.dockSpaces.filter((item: any) => item.id === resource[0]);
      if (dn) {
        return dn[0].name;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  showStepTime(resource: any) {
    if (resource[0]) {
      return resource[0];
    } else {
      return "";
    }
  }

  showPriorityVehicle(event: any) {
    if (event) {
      if (parseInt(event[0].resource.priority_vehicle) == 1) {
        return "Sim";
      }
      if (parseInt(event[0].resource.priority_vehicle) == 2) {
        return "Não";
      }
      return " - ";
    }
    return " - ";
  }

  showPendency(params: any) {
    if (params) {
      if (params[0]) {
        if (Object.keys(params[0]).includes("resource")) {
          let hasPendencies = [];
          params[0].resource.forEach((element: any) => {
            if (element.solved === false) {
              hasPendencies.push(1);
            }
          });
          if (hasPendencies.length > 0) {
            return "Sim";
          } else {
            return "Não";
          }
        } else {
          return "Não";
        }
      } else {
        return "Não";
      }
    } else {
      return "Não";
    }
  }

  //Abre o modal para mostrar as informações de todas as exceções recebidas pelo checkin
  showDialogExceptions(checkinResource: any) {
    let dataExceptions = [];

    //Arrays can't be used for switch case, so 7 Ifs it is
    //TODO: Make these ifs more dynamic in the future?
    if (checkinResource[0].route) {
      let destinyRoute: any[] = [];

      const destinyRoute_ids = checkinResource[0].route;

      this.listDestinyLocation.forEach((item) => {
        if (destinyRoute_ids.find((o: any) => o.id == item.id)) {
          destinyRoute.push(item.name + "; " + item["sub-area"]);
        }
      });
      dataExceptions.push({ name: "route", values: destinyRoute });
    }
    if (checkinResource[0].ot_problem) {
      let exceptionNames: any[] = [];
      const exception_ids = checkinResource[0].ot_problem;

      this.listOtProblems.forEach((item) => {
        if (exception_ids.includes(item.value)) {
          exceptionNames.push(item.label);
        }
      });

      if (checkinResource[0].other_ot_problem) {
        checkinResource[0].other_ot_problem.forEach((item: any) => {
          exceptionNames.push(item);
        });
      }
      // Find para encontrar o Subtitle na tabela (SupportData -> actions) dentro da lista de excecoes.
      const subTitle = this.listAction.find(
        (x: any) =>
          x.component_input_provider_api &&
          x.component_input_provider_api.ot_problem,
      );
      dataExceptions.push({
        name: "ot_problem",
        values: exceptionNames,
        subTitle: subTitle?.subtitle ?? "Exceções no Checklist",
      });
    }

    if (checkinResource[0].exception_received) {
      let exceptionNames: any[] = [];

      const exception_ids = checkinResource[0].exception_received;

      this.listReceivedWithException.forEach((item) => {
        if (exception_ids.includes(item.value)) {
          exceptionNames.push(item.label);
        }
      });

      if (checkinResource[0].other_exception_received) {
        checkinResource[0].other_exception_received.forEach((item: any) => {
          exceptionNames.push(item);
        });
      }

      dataExceptions.push({
        name: "exception_received",
        values: exceptionNames,
      });
    }

    if (checkinResource[0].loading_denied) {
      let exceptionNames: any[] = [];

      const exception_ids = checkinResource[0].loading_denied;

      this.listLoadingDenied.forEach((item) => {
        if (exception_ids.includes(item.value)) {
          exceptionNames.push(item.label);
        }
      });

      if (checkinResource[0].other_loading_denied) {
        checkinResource[0].other_loading_denied.forEach((item: any) => {
          exceptionNames.push(item);
        });
      }

      dataExceptions.push({ name: "loading_denied", values: exceptionNames });
    }

    if (checkinResource[0].unloading_denied) {
      let exceptionNames: any[] = [];

      const exception_ids = checkinResource[0].unloading_denied;

      this.listUnloadingDenied.forEach((item) => {
        if (exception_ids.includes(item.value)) {
          exceptionNames.push(item.label);
        }
      });

      if (checkinResource[0].other_unloading_denied) {
        checkinResource[0].other_unloading_denied.forEach((item: any) => {
          exceptionNames.push(item);
        });
      }

      dataExceptions.push({ name: "unloading_denied", values: exceptionNames });
    }

    if (checkinResource[0].loading_problem) {
      let exceptionNames: any[] = [];

      const exception_ids = checkinResource[0].loading_problem;

      this.listLoadingProblems.forEach((item) => {
        if (exception_ids.includes(item.value)) {
          exceptionNames.push(item.label);
        }
      });

      if (checkinResource[0].other_loading_problem) {
        checkinResource[0].other_loading_problem.forEach((item: any) => {
          exceptionNames.push(item);
        });
      }

      dataExceptions.push({ name: "loading_problem", values: exceptionNames });
    }

    if (checkinResource[0].direction_problem) {
      let exceptionNames: any[] = [];

      const exception_ids = checkinResource[0].direction_problem;

      this.listDirectionProblem.forEach((item) => {
        if (exception_ids.includes(item.value)) {
          exceptionNames.push(item.label);
        }
      });

      if (checkinResource[0].other_direction_problem) {
        checkinResource[0].other_direction_problem.forEach((item: any) => {
          exceptionNames.push(item);
        });
      }

      dataExceptions.push({
        name: "direction_problem",
        values: exceptionNames,
      });
    }

    if (checkinResource[0].invalidated_documentation) {
      let exceptionNames: any[] = [];

      const exception_ids = checkinResource[0].invalidated_documentation;

      this.listInvalidatedDocumentation.forEach((item) => {
        if (exception_ids.includes(item.value)) {
          exceptionNames.push(item.label);
        }
      });

      if (checkinResource[0].other_invalidated_documentation) {
        checkinResource[0].other_invalidated_documentation.forEach(
          (item: any) => {
            exceptionNames.push(item);
          },
        );
      }

      dataExceptions.push({
        name: "invalidated_documentation",
        values: exceptionNames,
      });
    }

    const pageOptions =
      this.account.system_client.resource.environment.pages[this.actualPage!];
    const dialogTitle: any = []
      .concat(pageOptions.columns)
      .find((o: any) => o.name == "exception") || {
      title: "Lista de Exceções",
    };
    this.dialog.open(DialogExceptionsComponent, {
      data: {
        title: dialogTitle.title || "Lista de Exceções",
        dataExceptions: dataExceptions,
      },
    });
  }

  showException(exceptions: any) {
    return exceptions;
  }

  showPriority(resource: any) {
    const color = resource[0].priority
      ? this.account.system_client.resource.priority_color
      : "#ffaa0000";
    const name = resource[0].priority ? "Prioridade" : "";
    return { color, name };
  }

  showHighWeight(resource: any) {
    const color =
      +resource[0].weight > 10000
        ? this.account.system_client.resource.weight_color
        : "#ffaa0000";
    const name = +resource[0].weight ? "Processo de Balança" : "";
    return { color, name };
  }

  showLinked(resource: any) {
    const color = resource[0].trucker_checkin_id
      ? this.account.system_client.resource.linked_color
      : "#6e0000";
    const name = resource[0].trucker_checkin_id ? "Com vínculo" : "Sem vínculo";
    return { color, name };
  }

  showValet(resource: any) {
    const name = resource[0].valet_mode_activated ? "Sim" : "Não";
    return name;
  }

  timeElapsedToNow(timestamp: any) {
    const now = moment();
    const tsEvaluated = moment(timestamp[0]);
    return moment
      .duration(now.diff(tsEvaluated), "milliseconds")
      .format("dd[d]:hh[h]:mm[min]");
  }

  prepareParams(dataObj: any, paramArray: any[]) {
    let params: any[] = [];
    paramArray.forEach((element) => {
      let paramsA = element.split(".");
      let paramValue;
      if (paramsA.length > 1) {
        paramValue = paramsA.reduce(
          (o: any, i: any, index: any, values: any) => {
            return o[i] ? o[i] : values.filter((v: any) => v).join(".");
          },
          dataObj,
        );
      } else {
        paramValue = dataObj[element];
      }

      params.push(paramValue);
    });

    return params;
  }

  processColumns(colValue: any, column: any, data: any) {
    try {
      if (column.transform_field) {
        const params = this.prepareParams(data, column.transform_params);
        const thisScope: any =
          this[column.transform_method as keyof typeof this] ||
          function () {
            return null;
          };
        return thisScope.apply(this, [params]);
      } else {
        return colValue;
      }
    } catch (error) {
      return "";
    }
  }

  formatHeader(item: any) {
    let result;

    this.columns.map((col: any) => {
      if (col.name == item) {
        result = col.label;
      } else if (item == "actions") {
        result = "";
      }
    });
    return result;
  }

  fromScheduleFieldOptions(param: any) {
    let [id, option, _default = ""] = param;

    const found: any = []
      .concat(this.scheduleFieldsOptions[option])
      .filter((x) => x)
      .find((o: any) => o.id == id);

    return found ? found.name : _default;
  }

  getDynamicForm(action: any, item: any) {
    const dialogRef = this.dialog.open(DynamicDialogComponent, {
      panelClass: action.hasOwnProperty("dialog_size")
        ? [action.dialog_size]
        : ["dialog-small"],
      disableClose: false,
      data: {
        title: action.title ? action.title : "Preencha os dados",
        form_name: action.dynamic_form_name,
        message: action.dialogue_message,
        item: JSON.parse(JSON.stringify(item)),
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.reload();
    });
  }

  getWeightForm(action: any, item: any) {
    const dialogRef = this.dialog.open(WeightDialogComponent, {
      panelClass: ["dialog-medium"],
      disableClose: false,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.reload();
    });
  }

  getFlowControl(action: any, item: any) {
    const dialogRef = this.dialog.open(FlowDialogComponent, {
      panelClass: ["dialog-small-plus"],
      disableClose: false,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.reload();
    });
  }

  getExceptionControl(action: any, item: any) {
    const dialogRef = this.dialog.open(ExceptionsControlDialogComponent, {
      panelClass: ["dialog-full"],
      disableClose: false,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.reload();
    });
  }

  getOptionsDialog(action: any, item: any) {
    const dialogRef = this.dialog.open(OptionsDialogComponent, {
      panelClass: [action.dialog_size],
      disableClose: false,
      data: {
        title: action.title ? action.title : "Preencha os dados",
        form_name: action.dynamic_form_name,
        message: action.dialogue_message,
        item: JSON.parse(JSON.stringify(item)),
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe((action: any) => {
      if (item) {
        const key = action.action as keyof typeof this;
        (this[key] as Function)(action, { id: null });
      } else {
        this.reload();
      }
    });
  }

  actions(click: string, action: any, item: any) {
    const thisScope: any = this[click as keyof typeof this];
    return thisScope(action, item);
  }

  private searchingProcess() {
    this.searching = true;
    setTimeout(() => {
      if (this.searching) {
        this.rawData = [];
        this.dataSource.data = [];
      }
    }, 1000);
  }

  private setDataSource(data: any[], actualPage: string | undefined): void {
    this.allDataAvailable = true;
    this.searching = false;
    if (data instanceof Array && actualPage == this.actualPage) {
      this.rawData = data;
      this.dataSource.data = this.formatData(data);
    }
  }

  private orderBy(name: string, pageOptions: any) {
    const column: any = pageOptions.columns.find(
      (opt: any) => opt.name == name,
    );

    if (column) {
      return column.order_by || name;
    }

    return name;
  }

  sorted(col: string): boolean {
    if (col === "actions") {
      return true;
    }

    const pageOptions =
      this.account.system_client.resource.environment.pages[this.actualPage!];
    const column: any = pageOptions.columns.find((opt: any) => opt.name == col);

    return !Boolean(column?.order_by);
  }

  snackBarMessage(msg: string) {
    this.snackBar.open(msg, "OK", {
      duration: 3000,
      horizontalPosition: "left",
      verticalPosition: "bottom",
    });
  }

  showBSActions(element: any) {
    const bottomSheetRef = this._bottomSheet.open(BottomSheetActionsComponent, {
      data: JSON.stringify({
        action_list: this.listAction,
        id: element.actions.value.id,
      }),
    });
    bottomSheetRef.afterDismissed().subscribe((action) => {
      if (action) {
        this.changeStatus(element.actions.value, action);
      }
    });
  }

  plusBtn() {
    if (this.addButtonParams.action === "getDynamicForm") {
      this.getDynamicForm(this.addButtonParams, { id: null });
    }
    if (this.addButtonParams.action === "getOptionsDialog") {
      this.getOptionsDialog(this.addButtonParams, { id: null });
    }
  }

  print(id: number) {
    this.agendamentoService
      .downloadPDF("checkin_details_pdf", id)
      .subscribe((r: any) => {
        const fileURL = URL.createObjectURL(r);
        window.open(fileURL, "_blank");
      });
  }

  makeSound(status: string) {
    console.log("entrou");
    const hasStatus = this.listStatusSound.some((item: any) => item === status);
    if (hasStatus) {
      this.soundService.changeSoundState(
        this.account.has_sound,
        this.account.id,
      );
    }
  }

  getAllowSound() {
    Object.entries(
      this.account.system_client.resource.environment.pages,
    ).forEach(([key, page]: [string, any]) => {
      if (this.actualPage === page.id) {
        this.allowSound = page.allowSound;
      }
    });

    if (this.allowSound) {
      this.soundService.changeSoundState(
        this.account.has_sound,
        this.account.id,
      );
    }
  }

  getSoundStatus() {
    Object.entries(
      this.account.system_client.resource.environment.pages,
    ).forEach(([key, page]: [string, any]) => {
      if (this.actualPage === page.id) {
        this.listStatusSound = page.statusSound;
      }
    });
  }

  async getScaleData(id: number): Promise<any[]> {
    try {
      const scale = await lastValueFrom(this.checklistServide.getScaleData(id));

      const teste = [
        {
          weight: 50,
          volume: 30,
          pouch: "TY300",
          typist: ["teste", "teste2", "teste3"],
          lecturer: ["teste4", "teste5"],
          assistant: ["teste5", "teste6"],
          auxiliary: ["teste8", "teste9"],
          accessory_type: ["a1", "a2"],
          name_leader: ["lider1", "lider2", "lider3"],
        },
      ];

      return teste; //scale.data || [];
    } catch (error) {
      console.error("Erro ao buscar dados de escala:", error);
      throw error;
    }
  }

  updateFilterList($event: any) {
    console.log("updateFilterList");
    console.log($event);
    this.btnFilterList = $event;
    const pageOptions =
      this.account.system_client.resource.environment.pages[this.actualPage!];
    const actualPage = this.actualPage;

    let formatedData: any = this.formatFilterData(this.btnFilterList);

    if (!Object.hasOwn(formatedData, "with_status")) {
      formatedData["with_status"] = pageOptions.with_status.join("|");
    }

    const options = {
      orderBy: this.orderBy(this.sort?.active || "created_at", pageOptions),
      sortedBy: this.sort.direction ? this.sort.direction : "ASC",
      pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
      search: this.searchFilter.value || "",
      ...formatedData,
    };

    this.searchingProcess();
    this.checkinDataSubscribe = this.checkinService
      .checkins(this.endpoint, options)
      .pipe(
        map((response: any) => {
          this.paginator.pageIndex = 0;
          this.paginatorLength = response.total;
          this.paginatorPageSize = response.per_page;
          return response.data;
        }),
        catchError(() => {
          this.searching = false;
          return observableOf([]);
        }),
      )
      .subscribe((data: any[]) => {
        this.setDataSource(data, actualPage);
        this.driverCheckinIdList = data.map((item: any) => item.id);
      });
  }

  private formatFilterData(filters: Array<Record<string, string>>) {
    // funcao que recebe a lista de filtros, e retorna um objeto pra mandar pra api
    // criar um objeto vazio, fazer um loop for na lista enviada, validar se o ref_name existe no objeto, se não
    // adiciona com chave/valor se existir pega o valor e concatena uma string: " | " + o valor
    // retorna o objeto

    const options: Record<string, string> = {};

    for (let i = 0; i < filters.length; i++) {
      if (Object.hasOwn(options, filters[i].ref_name)) {
        let ref_id: string = options[filters[i].ref_name];
        let stringValue: string = ref_id + "|" + filters[i].ref_id;
        options[filters[i].ref_name] = stringValue;
      } else {
        options[filters[i].ref_name] = filters[i].ref_id;
      }
    }
    return options;
  }

  removeFilterItem(idx: number) {
    this.btnFilterList.splice(idx, 1);
    this.updateFilterList(this.btnFilterList);
  }
}
